@font-face { font-family: 'Acme', sans-serif !important;
    src: url('https://fonts.cdnfonts.com/css/futura-pt?styles=117663,117667,117668,117664,117666,117662,117665') }

    @import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');
    @import url("https://fonts.cdnfonts.com/css/conthrax");
   

    .modal {
      
      font-size: 12px;
	    background-position: center center;
      background-repeat: no-repeat;
      max-width: 100% !important;
      background-size: cover;
      border-radius: 30px;
      font-family: "Conthrax", sans-serif !important;
      
      
     
    }
    .blue{
      color:rgb(0 213 255 / 80%) !important;

    }

  
 
    .css-1c02ksv{
      font-size: 13px !important;
    }

    .css-ipf879{
      background-color: #00000000 !important;
    }
    .css-1tccf60 {
      font-size: 13px !important;
    }



  .css-1l4w6pd {
    border-radius: 30px !important;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 13px !important;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
   
    flex-direction: column;
}

  .fileSubmit {
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
  
    background-color: transparent;
    margin-left: auto;
    margin-right: auto;
    border-radius: 60px;
    font-size: 15px;
}
  /* Style the file input, make it transparent and cover the container */
  .fileSubmit input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Style the text inside the input container */
  .fileSubmit .file-input-label {
    display: inline-block;
    margin-right: 10px;
  }
  

    .css-1tffnd6 {
      display: grid;
      grid-gap: 0px;
      grid-template-columns: repeat(1, 1fr);
      max-width: 360px !important;
      margin: 0 auto; /* Center align the grid within its parent */
      
      align-items: center; /* Center align items vertically */
  }

    .chakra-ui-light{
      background-size: cover;
    }
    .popup-content {
      background-color: #070710 !important;
      width: 85% !important;
      margin: auto;
      border-radius: 30px;
  
   
  }
  
  /* Apply the 40% max width for screens wider than a certain breakpoint */
  @media (min-width: 768px) {
      .popup-content {
          max-width: 40% !important;   
      }
  }
    

    .popup-content::after{
      overflow-y: scroll !important;
      
    }
    .form .form-control {

      padding: 8px;
      border: 1px solid rgb(66, 66, 66);
      border-radius: 10px;
      align-items: center; /* Center items horizontally */
      box-sizing: border-box; /* Ensure consistent box model */
      font-family: "Conthrax", sans-serif !important;
    }


   

    .form-control{
      border-radius: 30px;
      
      font-size: 14px;
      background: #070710 !important;
      width: 70% !important;
      padding: 7px 5px;
      margin: auto;
      text-align: center;
      font-weight: 900 !important;
      color: #c0c0c0 !important;
      font-family: "Conthrax", sans-serif !important;
      border-color: #52525200 ;
      border: 2px !important;
      box-shadow: inset 0 0 20px rgb(0 0 0 / 50%), inset 20px 0 30px rgb(0 0 0 / 34%), inset -20px 0 30px rgb(22 240 255 / 38%), inset 20px 0 100px rgb(0 0 0 / 0%), inset -20px 0 100px rgb(5 5 5 / 50%), 0 0 5px rgb(255 255 255), -5px 0 5px rgb(175 175 175), 5px 0 5px !important; }

    .custom-date-picker {
      font-size: 15px !important;
      border-radius: 30px;
      background-color: transparent;
      border: 2px solid #e8e8e898;
      margin: auto !important;
      padding: 15px;
     
      
  }

  .custom-date-picker::placeholder{
    text-align: center!important;
    font-size: 13px !important;
  }



  



















  


    
    
    .modal > .content {
      width: 100%;
      padding: 10px 5px;
      color:#cfcece;
      font-weight: bold;
      border:none !important;
      font-family: "Conthrax", sans-serif !important;
      border-color: #d5d5d5 !important;
      
    }
    
    .modal > .actions {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;
    }
    .css-1gx57aq{
      background: #00000000 !important;
      color: #ffffff !important;
    }
    .spacer{
      padding-top: 10px;
    }


    .modal > .headerMD {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;
      font-size: 20px;
      font-weight: bolder;
      color: #cdcdcd;
    }
    .ju367vex {
      color: rgb(0 0 0) !important;
  }

    .modal > .inform {
      width: 100%;
      padding: 10px 5px;
      margin: auto;
      text-align: center;
      font-size: 15px;
      font-weight: bolder;
      color: #0ff;
    }

    .nftMint1{
      color: rgb(255 255 255 / 95%) !important;
      font-size: 13px !important;
    }

    .nftMint{
      color: rgb(255 255 255) !important;
    font-size: 13px !important;
    }

    


    .ju367v9c {
      background: rgb(255 255 255) !important;
  }

  .responseTop{
    color:rgb(255 255 255) !important;
  }

  .padd{
    padding-top: 15px !important;
  }

  .css-54vq0f {
    background-color: #01010019 !important;
    padding: 15px !important;
    border-radius: 30px !important;
  }

  .css-td8gbm {
    background: #ec2cff !important;
  }

  .css-1kp110w:focus-visible, .css-1kp110w[data-focus-visible] {
    z-index: 1;
    border-color: #f4ca04;
    box-shadow: 0 0 0 1px #f4ca04 !important;
  }

  .css-flzx43 {
    height: 100%;
    transition-property: var(--chakra-transition-property-common);
    transition-duration: var(--chakra-transition-duration-slow);
    background-color: #d708df !important;
    border-radius: #010100 !important;
}


  
    .css-jv498s{
      display: flex;
    flex-direction: column;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    --card-bg: none !important;
    background-color: var(--card-bg);
    color: var(--chakra-colors-chakra-body-text);
    border-radius: var(--chakra-radii-md);
    --card-padding: var(--chakra-space-5);
    box-shadow: var(--chakra-shadows-base);
    }

h1,h2,h3{
  font-family: "Conthrax", sans-serif !important;
}



/**
  * Meetup
  *
  * @author jh3y - jheytompkins.com
*/
@-webkit-keyframes meetup {
    0%, 100% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(0); }
    50% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%); } }
  @keyframes meetup {
    0%, 100% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(0);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(0); }
    50% {
      -webkit-transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%);
              transform: rotate(calc(var(--rotation) * 1deg)) translateY(300%); } }
  
  .meetup {
    -webkit-animation: spin 1s infinite linear;
            animation: spin 1s infinite linear;
    height: 10px;
    width: 10px; }
    .meetup div {
      height: 100%;
      position: absolute;
      width: 100%;
      -webkit-animation: meetup 1.25s infinite ease;
              animation: meetup 1.25s infinite ease;
      background: rgb(7, 7, 7);
      border-radius: 100%; }
      .meetup div:nth-child(1) {
        --rotation: 90; }
      .meetup div:nth-child(2) {
        --rotation: 180; }
      .meetup div:nth-child(3) {
        --rotation: 270; }
      .meetup div:nth-child(4) {
        --rotation: 360; }
  
  @-webkit-keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }
  
  @keyframes spin {
    to {
      -webkit-transform: rotate(360deg);
              transform: rotate(360deg); } }





              body {
                margin: auto;
                font-family: "Conthrax", sans-serif !important;
                overflow: auto;
                background-image: url(https://i.ibb.co/xz55BNG/Shield-BG.png) !important;
                background-size: cover;
                min-height: 100%;
                background-position: center center;
               
               
                
                background-attachment: fixed;
            }
            
            @keyframes gradient {
                0% {
                    background-position: 0% 0%;
                }
                50% {
                    background-position: 100% 100%;
                }
                100% {
                    background-position: 0% 0%;
                }
            }
            
            .wave {
                background-color: linear(45deg, #0017e7, #060026);
                border-radius: 1000% 1000% 0 0;
                position: fixed;
                width: 200%;
                height: 12em;
                animation: wave 10s -3s linear infinite;
                transform: translate3d(0, 0, 0);
                opacity: 0.8;
                bottom: 0;
                left: 0;
                z-index: -1;
            }
            
            .wave:nth-of-type(2) {
                bottom: -1.25em;
                animation: wave 18s linear reverse infinite;
                opacity: 0.8;
                color:rgb(0, 26, 199)
            }
            
            .wave:nth-of-type(3) {
                bottom: -2.5em;
                animation: wave 20s -1s reverse infinite;
                opacity: 0.9;
                color:rgb(0, 14, 29)
            }
            
            @keyframes wave {
                2% {
                    transform: translateX(1);
                }
            
                25% {
                    transform: translateX(-25%);
                }
            
                50% {
                    transform: translateX(-50%);
                }
            
                75% {
                    transform: translateX(-25%);
                }
            
                100% {
                    transform: translateX(1);
                }
            }

            .swal2-container.swal2-backdrop-show, .swal2-container.swal2-noanimation {
              background: rgb(0 0 0 / 84%) !important;
          }
            .colored-toast.swal2-icon-success {
              background-color: #296f00 !important;
            }
            
            .colored-toast.swal2-icon-error {
              background-color: #6c0000 !important;
            }
            
            .colored-toast.swal2-icon-warning {
              background-color: #783800 !important;
            }
            
            .colored-toast.swal2-icon-info {
              background-color: #005574 !important;
            }
            
            .colored-toast.swal2-icon-question {
              background-color: #00726a !important;
            }
            
            .colored-toast .swal2-title {
              color: rgb(101, 101, 101);
            }
            
            .colored-toast .swal2-close {
              color: rgb(47, 47, 47);
            }
            
            .colored-toast .swal2-html-container {
              color: rgb(27, 27, 27);
            }

            .popup-overlay {
              background:rgb(32 32 32 / 77%);
              overflow: scroll !important;
            }

            .swal2-html-container, .swal2-title{
             color: #ffffff;
             font-size: 20px !important;
              font-size: medium;
              font-family: "Conthrax", sans-serif !important;
            }

            .coldrsp{
              font-family: "Conthrax", sans-serif !important;
              font-size: medium;
            }
            .swal2-fire, .swal2-title{
              text-transform: uppercase;
              

  background-image: linear-gradient(
    -225deg,
    #ffffff 0%,
    #ffffff 29%,
    #ffffff 67%,
    #ffffff 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
      font-size: 30px;;
            }
            .swal2-border-radius { -webkit-border-radius: 40 !important; -moz-border-radius: 40 !important; border-radius: 40 !important; }
.lgl{
  
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 20px;
  border: 2px !important;
  border-color: #00ffe5 !important;



 
}
.css-1sgs4n2{background: #000000 !important;}


.lglimg{
  
  animation: rotateY 6s infinite;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 5px;


  }


/* animation */
@-webkit-keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@-o-keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@keyframes btnWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }}

@-webkit-keyframes imgWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@-o-keyframes imgWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}
}
@keyframes imgWiggle {
	0% {-webkit-transform: rotate(0deg);}
	2% {-webkit-transform: rotate(-8deg);}
	3.5% {-webkit-transform: rotate(8deg);}
	5% {-webkit-transform: rotate(0deg);}
	100% {-webkit-transform: rotate(0deg);}

}

@keyframes rotateY {
  0% {
    transform: rotateY(0deg);
}
25% {
    transform: rotateY(0deg);
}
75% {
    transform: rotateY(360deg);
}
100%{
    transform: rotateY(360deg);
}
}



.sideimg{
  animation: rotateY 5s infinite;
  border-radius: 30px;
  border: 5px solid #555;
  border-color: #00ffea;
  

  box-shadow:
  inset 0 0 20px rgb(0, 30, 79),      /* inner white */
  inset 20px 0 30px rgb(0, 30, 79),   /* inner left magenta short */
  inset -20px 0 30px rgb(0, 30, 79),  /* inner right cyan short */
  inset 20px 0 100px rgb(0, 30, 79),  /* inner left magenta broad */
  inset -20px 0 100px rgb(0, 30, 79), /* inner right cyan broad */
  0 0 1px rgb(255, 255, 255),            /* outer white */
  -10px 0 10px rgb(173, 190, 255),        /* outer left magenta */
  10px 0 10px rgb(245, 252, 255);         /* outer right cyan */
}

.cardbody{
  color: #cfcece !important;
  box-shadow :
  inset 0 0 20px rgba(86, 86, 86, 0.03),      /* inner white */
  inset 20px 0 30px rgba(180, 180, 180, 0.078),   /* inner left magenta short */
  inset -20px 0 30px rgba(180, 180, 180, 0.075),  /* inner right cyan short */
  inset 20px 0 100px rgba(180, 180, 180, 0.042),  /* inner left magenta broad */
 
  0 0 5px rgb(171, 171, 171),            /* outer white */
  -5px 0 5px rgb(200, 200, 200),        /* outer left magenta */
  5px 0 5px rgb(230, 230, 230) !important; 
  
}

.css-i857na{
  background: #181818 !important;
}

.css-hxn3v0 {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(1, 1fr);
  border-radius: 30px !important;
  box-shadow :
  inset 0 0 20px rgba(90, 3, 96, 0.501),      /* inner white */
  inset 20px 0 30px rgb(90, 3, 96, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(90, 3, 96, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(90, 3, 96, 0.501),  /* inner left magenta broad */
 
  0 0 5px rgb(34, 184, 249),            /* outer white */
  -5px 0 5px rgb(106, 34, 249),        /* outer left magenta */
  5px 0 5px rgb(249, 34, 235) !important; 
  padding-top: 15px; 
  padding-bottom: 15px;
}



.bnbpool{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.css-433779{
  color: #cfcece !important;
  font-size: 20px !important;
}




.css-8pbt74{
  background: #00000000 !important;
}

.css-bnoitd {
  padding: var(--card-padding);
  -webkit-flex: 1 1 0%;
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  overflow: hidden;
  border-top-left-radius: 0px; 
  border-top-right-radius: 0px; 
  border-radius: 10px !important;
}
.css-iquc7e{
  border-color: rgb(77, 77, 77) !important;;
  background: #282828b8 !important;
  box-shadow :
  inset 0 0 20px rgb(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 1px rgba(0, 94, 255, 0.724),            /* outer white */
  -10px 0 10px rgb(0, 145, 255),        /* outer left magenta */
  10px 0 10px rgb(0, 162, 255);      /* outer right cyan */
  display: flex !important;
  border-width: 2px;
  border-radius: 30px !important;
  
}

.css-18cq000{
  background: #00000000 !important;

}


.linebtm{
  
  border-color:rgb(57 57 255) !important;
  background: #282828b8 !important;
  box-shadow :
  inset 0 0 20px rgb(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 1px rgba(0, 94, 255, 0.724),            /* outer white */
  -10px 0 10px rgb(0, 69, 122),        /* outer left magenta */
  10px 0 10px rgb(0, 8, 255);      /* outer right cyan */
  display: flex !important;
  border-width: 2px;
  border-radius: 30px !important;
  

}


.chakra-card__footer{
background: transparent !important;
}

.css-1trxw3w{
  background: transparent !important;
}
.css-og0ais{
  background: transparent !important;
  border-bottom-left-radius: 30px;
  border-radius: 30px !important;
  box-shadow :
  inset 0 0 20px rgb(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 5px rgb(34, 69, 249),            /* outer white */
  -5px 0 5px rgb(34, 69, 249),        /* outer left magenta */
  5px 0 5px rgb(34, 69, 249) !important;      /* outer right cyan */
}

.css-cbyey{
  background: transparent !important;
}

 
.css-19c8aov{
  grid-gap: 0px !important;
  
  background-color: transparent !important; 


  color: #0ff;
  border-radius: 30px;
  border-color: #ffffff;
  box-shadow :
  inset 0 0 20px rgb(0, 30, 79),      /* inner white */
  inset 20px 0 30px rgb(0, 30, 79),   /* inner left magenta short */
  inset -20px 0 30px rgb(0, 30, 79),  /* inner right cyan short */
  inset 20px 0 100px rgb(0, 30, 79),  /* inner left magenta broad */
  inset -20px 0 100px rgb(0, 30, 79), /* inner right cyan broad */
  0 0 1px rgb(255, 255, 255),            /* outer white */
  -10px 0 10px rgb(173, 190, 255),        /* outer left magenta */
  10px 0 10px rgb(245, 252, 255);         /* outer right cyan */

 

}

  
.pompool{

  background-image: linear-gradient(
    -180deg,
    #80b3ff 0%,
    #80b3ff 29%,
    #80b3ff 67%,
    #80b3ff 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
      font-size: 30px;
}



.pompool1{

  background-image: linear-gradient(
    -180deg,
    #c3c3c3 0%,
    #c2c2c2 29%,
    #c3c3c3 67%,
    #c1c1c1 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
      font-size: 30px;
}



@keyframes textclip {
  to {
    background-position: 200% center;
  }
}


.css-2l2wev{
  
  border-color: rgb(77, 77, 77) !important;;
  background: #282828b8 !important;
  box-shadow :
  inset 0 0 20px rgba(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 5px rgb(34, 184, 249),            /* outer white */
  -5px 0 5px rgb(106, 34, 249),        /* outer left magenta */
  5px 0 5px rgb(249, 34, 235) !important;
  display: flex !important;
  
  
}

.css-11a8r05{
  background-color: #27272782!important;
  background-size: cover !important;
  color: #e9e9e9  !important;
  border-color: #00000000 !important;
  font-size: 15px !important;
 

}

.css-1dhyq3r{
  display:flex !important;
  min-width: 300px;
}

.css-17ifo8b{
  background-color: #00000000 !important
}

.css-lg2zl1 {
  color:#ed207c !important;
  margin: 10px !important;
  font-size: 11px !important;
}

.css-1tccf60{
  color: #cfcece;
}

.iekbcc0 {
  border: 0;
  box-sizing: border-box;
  font-size: 11px !important;
  line-height: normal;
  margin: 0;
  padding: 0;
  text-align: left;
  vertical-align: baseline;
  -webkit-tap-highlight-color: transparent;
}

.css-1sl53ol{
  color: #cfcece;
  font-family: "Conthrax", sans-serif !important;
  font-size: 18px !important;
  font-weight: 500 !important;
}

.cardtxt{
  color: #e9e9e9 !important;
  font-family: "Conthrax", sans-serif !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  text-align: center;
}

.cardtxthead{
  color: #e9e9e9 !important;
  font-family: "Conthrax", sans-serif !important;
  font-size: 20px !important;
  font-weight: 500 !important;
  text-align: center;
}

.cardtxt1{
  color: #f6f6f6 !important;
  font-family: "Conthrax", sans-serif !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.headertxt{

  color: #ffffff !important;
  font-size: 25px !important;
}

.txtinbar{
  
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #005177 0%,
    #0061d8 29%,
    #0089d3 67%,
    #00ffe5 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
      font-size: 23px;
}


.btntxt{
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #cdcdcd 0%,
    #cdcdcd 29%,
    #cdcdcd 67%,
    #cdcdcd 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
      font-size: 18px;

}




.swal2-popup {
  background: #141414 !important;
  color: #9c9c9c !important;
  border-radius: 60px !important;
  box-shadow: inset 0 0 20px rgba(237, 32, 124, 0.855), inset 20px 0 30px rgba(237, 32, 124, 0.855), inset -20px 0 30px rgba(237, 32, 124, 0.855), inset 20px 0 100px rgba(237, 32, 124, 0.855), inset -20px 0 100px rgba(237, 32, 124, 0.855), 0 0 5px rgba(237, 32, 124, 0.855), -5px 0 5px rgb(255 0 221), 5px 0 5px rgb(255 0 221)!important;
}


.swal2-styled.swal2-confirm {
  border: 0;
  border-radius: 30px !important;
  background: initial;
  background-color: #141414 !important;
  color: #dbdbdb !important;
  font-size: 1em;
}

.btnlabel{
  font-size: 20px !important;
  text-transform: uppercase;


  color: rgb(0 213 255 / 80%);


  
  
  font-weight:900;

}

.btnsubmit{
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #cdcdcd 0%,
    #cdcdcd 29%,
    #cdcdcd 67%,
    #cdcdcd 100%
  );
  color: #ffffff;
  display: inline-block !important;
  font-weight: 450 !important;
 
  border-color: #555;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;

}

.btnsubmit2{
  text-transform: uppercase;
  margin-top: px;
  background-image: linear-gradient(
    -225deg,
    #cdcdcd 0%,
    #cdcdcd 29%,
    #cdcdcd 67%,
    #cdcdcd 100%
  );
  color: #fff;
  display: inline-block !important;
  font-weight: 450 !important;
 
  border-color: #555;
  background-clip: text;
  text-fill-color: transparent;
  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;

}

.css-12anu69{
  border-radius: 30px !important;
}







.btnsubmit1{
text-transform: uppercase;

  background-image: linear-gradient(
    -225deg,
    #cdcdcd 0%,
    #cdcdcd 29%,
    #cdcdcd 67%,
    #cdcdcd 100%
  );
  color: #fff;
  font-weight: 450 !important;
  border-color: #555;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  text-align: center;
  -webkit-text-fill-color: transparent;
  animation: textclip 2s linear infinite;
  border: 2px solid #8a8a8a;
  border-radius: 30px !important;

}




.element.style{
  position: none !important;
}

.css-1uqpm9{
  font-size: 13px !important;
  font-weight:bolder !important;
  border-radius: 30px !important;
  
  

}

.ju367v11{
  font-family: "Conthrax", sans-serif !important;
}

.xkgoko{
  background-size: cover;
}

.stakebox{
  border: 2px;
  background-color: #41414135;
  box-shadow :
  inset 0 0 20px rgb(43, 43, 43, 0.501),      /* inner white */
  inset 20px 0 30px rgb(43, 43, 43, 0.501),   /* inner left magenta short */
  inset -20px 0 30px rgb(43, 43, 43, 0.501),  /* inner right cyan short */
  inset 20px 0 100px rgb(43, 43, 43, 0.501),  /* inner left magenta broad */
  inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */
  0 0 5px rgb(34, 69, 249),            /* outer white */
  -5px 0 5px rgb(34, 69, 249),        /* outer left magenta */
  5px 0 5px rgb(34, 69, 249) !important;      /* outer right cyan */


}
































.swal2-icon.swal2-success [class^=swal2-success-circular-line]{
  background-color: transparent !important;
}
.swal2-icon.swal2-success .swal2-success-fix {
  background-color: transparent !important;

}


@-webkit-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-moz-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-o-keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes outerRotate1 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@-moz-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@-o-keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@keyframes outerRotate2 {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}
@-webkit-keyframes textColour {
  0% {
    color: #fff;
  }
  100% {
    color: #3BB2D0;
  }
}
@-moz-keyframes textColour {
  0% {
    color: #fff;
  }
  100% {
    color: #3BB2D0;
  }
}
@-o-keyframes textColour {
  0% {
    color: #fff;
  }
  100% {
    color: #3BB2D0;
  }
}
@keyframes textColour {
  0% {
    color: #fff;
  }
  100% {
    color: #3BB2D0;
  }
}
html {
  font-size: 62.5%;
}


.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: auto;
  background-image: url(https://i.ibb.co/xz55BNG/Shield-BG.png) !important;
  background-size: cover;
  min-height: 100%;
  background-position: center center;
 
 
  
  background-attachment: fixed;

  background-position: center;
}

#loadingImage {
  max-width: 100%;
  max-height: 100%;
  animation: zoomIn 5s infinite; /* Adjust the duration as needed */
}

@keyframes zoomIn {
  0% {
      transform: scale(0.5);
  }
 
  100% {
      transform: scale(1);
  }
}



/* Media query for mobile screens */
@media (max-width: 768px) {
	svg text {
		font-size: 50px;
	}
}
@keyframes stroke {
	0%   {
		fill: rgba(64, 64, 64, 0); stroke: rgb(10, 0, 124);
		stroke-dashoffset: 25%; stroke-dasharray: 0 50%; stroke-width: 2;
	}
	70%  {fill: rgba(47,95,191,0); stroke: #ffffff; }
	80%  {fill: rgba(191, 153, 47, 0); stroke: #ffffff; stroke-width: 3; }
	100% {
		fill: rgb(191, 172, 47); stroke: rgba(255, 231, 10, 0);
		stroke-dashoffset: -25%; stroke-dasharray: 50% 0; stroke-width: 0;
	}
}



.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust the height as needed or use a specific height for the wrapper */
}

/* Additional styles for the SVG text */
.wrapper svg {

  background-color: #242424;
    background-size: 100% 100%;
    background-attachment: fixed;; /* Add background color if necessary */
}

.toggle-container {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.toggle-button {
  padding: 8px 16px !important;
  border: none !important;
  background-color: #181818 ;
  color: #ffffff ;
  font-size: 16px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
  margin: 3px !important;
}

.toggle-button.active {
  background-color: #e7e7e7;
    color: #000000;
}

.css-bv57s4 {
  background-color: transparent !important;
  color: #dfdfdf !important;
}
.DaoHeader{
  border-color: rgba(77, 77, 77, 0.297) !important;
  background: #282828b8 !important;
  box-shadow: inset 0 0 20px rgba(43, 43, 43, 0.501), /* inner white */ inset 20px 0 30px rgb(43, 43, 43, 0.501), /* inner left magenta short */ inset -20px 0 30px rgb(43, 43, 43, 0.501), /* inner right cyan short */ inset 20px 0 100px rgb(43, 43, 43, 0.501), /* inner left magenta broad */ inset -20px 0 100px rgba(43, 43, 43, 0.501), /* inner right cyan broad */ 0 0 5px rgb(249, 245, 34), /* outer white */ -5px 0 5px rgb(249, 245, 34), /* outer left magenta */ 5px 0 5px rgb(249, 245, 34) !important;
  display: flex !important;

}

.css-19c8aov{
  box-shadow:none !important
}


.InfoTop {
  background: transparent !important;
  font-size: 10px !important;
  border: 0px !important;
  --card-: transparent !important;


}

.css-hj8hg5 {
  border: 2px solid #89898c !important;
  background-color: #070710;;
}

@keyframes glitchs {
  0% {
    transform: translate(0);
  }
  25% {
    transform: translate(-5px, 5px);
  }
  50% {
    transform: translate(5px, -5px);
  }
  75% {
    transform: translate(-5px, 5px);
  }
  100% {
    transform: translate(0);
  }
}

.text-glitch {
  animation: glitch 1s infinite;
}



@keyframes glitch {
  0% {
      border-color: #b0b0b06f;
  }
  25% {
      border-color: #87878765;
  }
  50% {
      border-color: #43434368;
      transform: translateY(1px);
  }
  75% {
      border-color: #87878765;
  }
  100% {
      border-color: #b0b0b06f;
  }
}



.NFTHead{
background-color: transparent !important;


position: relative !important;

font-size: 30px !important;


}

.NFTHead1{
  background-color: transparent !important;
  
  
  position: relative !important;

  font-size: 30px !important;
  border:none !important;
  
  
  }
.Btns{
  background-color: transparent !important;
box-shadow : none !important;
border: transparent!important;



font-size: 30px !important;
  
}


.button-container {
  display: flex;
  justify-content: center;
  gap: 10px; /* Adjust the spacing as needed */
  margin-top: 20px;
  flex-direction: column;
  align-items: center;
}

.close-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}


.separator-animated {
  position: relative;
  height: 2px;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0) 0%, rgb(209, 209, 210) 50%, rgba(0, 0, 0, 0) 100%);
}

.separator-animated.animated-true::before {
  content: "";
  position: absolute;
  width: 3%;
  height: 100%;
  backdrop-filter: blur(20px);
  animation-timeline: auto;
  animation-range-start: normal;
  animation-range-end: normal;
  background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0) 0%, var(--color-primary) 50%, rgba(0, 0, 0, 0) 100%);
  animation: 5s ease-in-out 0s infinite normal none running separateline;
}

@keyframes separateline {
  0% {
    left: 0;
  }
  100% {
    left: 110%;
  }
}

.w-100 {
    width: 100% !important;
}




.chatenai-separator.has-position-bottom {
  position: absolute;
  width: 100%;
  height: auto;
  bottom: -2px;
  left: 0;
  right: 0;
}
@media only screen and (max-width: 767px) {
  .chatenai-separator.has-position-bottom {
    bottom: -5px;
  }
}
.chatenai-separator.has-position-top {
  position: absolute;
  width: 100%;
  height: auto;
  left: 0;
  right: 0;
  bottom: 100%;
  top: auto;
}





































